import React, { Component } from 'react'
import axios from 'axios'

const API_URL = '/searches'

class Search extends Component {
    state = {
        query: '',
        results: []
    }

    getInfo = () => {
        axios.get(`${API_URL}?query=${this.state.query}`)
            .then(({ data }) => {
                this.setState({
                    results: data.results
                })
            })
    }

    handleInputChange = () => {
        this.setState({
            query: this.search.value
        }, () => {
            if (this.state.query && this.state.query.length > 1) {
                if (this.state.query.length % 2 === 0) {
                    this.getInfo()
                }
            }
        })
    }

    render() {
        return (
            <form>

                <input
                    placeholder="Search for users or groups.."
                    ref={input => this.search = input}
                    onChange={this.handleInputChange}
                    className="w-full h-16 px-3 rounded mb-8 focus:outline-none focus:shadow-outline text-xl px-8 shadow-lg"
                    autoFocus
                    onFocus={e => e.currentTarget.select()}

                />
                <Suggestions results={this.state.results} />
            </form>
        )
    }
}

const Suggestions = (props) => {
    const options = props.results.map(r => (

        <li className="transition text-gray-800 px-3 py-3 duration-500 ease-in-out hover:bg-green-100 hover:bg-opacity-75 transform hover:-translate-y-1 hover:scale-110" key={r.id}> <a href={r.url}>{r.name}</a>  </li>

    ))
    return <ul className="shadow-lg list-none bg-white px-20">{options}</ul>
}



export default Search


var ReactDOM = require('react-dom');

document.addEventListener('turbolinks:load', () => {
    var element = document.getElementById("incremental-search");
    if(element) {
        ReactDOM.render(<Search />, element);
    }
})
